import "./Footer.scss";
import { FaFacebookF, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import logo from '../../assets/hdlogo.png';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();

    return <footer className="footer">
        <div className="custom-shape-divider">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
            </svg>
        </div>
        <div className="footer-inner">
            <div className="background-image"></div>
            <div className="footer-content">
                <div className="col">
                    <img src={logo} alt="SHAHIANA.in" />

                    <div className="text">Discover the allure of Turkish lamps and let their warm glow weave stories of culture and elegance into your home. Explore our collection and embark on a journey of aesthetic delight with Shahiana.</div> <br />

                    <div className="text">Thank you for choosing Shahiana, Crafting Your Home with <i>Royalty</i>!</div>
                </div>
                <div className="col">
                    <div className="title">Other Links</div>
                    <div className="c-items">
                        <p className="ol" onClick={() => navigate(`/returnrules`)}>
                            Return Rules
                        </p>
                    </div>
                    <div className="c-items">
                        <p className="ol" onClick={() => navigate(`/rarp`)}>
                            Return and Refund Policy
                        </p>
                    </div>
                    <div className="c-items">
                        <p className="ol" onClick={() => navigate(`/shippingpolicy`)}>
                            Shipping Policy
                        </p>
                    </div>
                </div>

                <div className="col" >
                    <div className="title">Get in touch!</div>
                    <span className="text">Looking for something special? Have a question? Let us know, we'll get back to you soon.</span>
                    <div className="form">
                        <a className="wa-btn" href="https://wa.me/+918897271818?text=I have a query about..." target="_blank" rel="noopener noreferrer"><FaWhatsapp />Message us on WhatsApp</a>

                    </div>
                    <div className="social-icons">
                        <a href="https://www.facebook.com/profile.php?id=100092802851594&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                            <div className="icon">
                                <FaFacebookF fill="#1877F2" size={24} />
                            </div>
                        </a>
                        <a href="https://www.instagram.com/shahiana_in" target="_blank" rel="noopener noreferrer">

                            <div className="icon">
                                <FaInstagram fill="deeppink" size={24} />
                            </div>
                        </a>
                    </div>
                </div>

            </div>
            <div className="bottom-bar">
                <div className="bottom-bar-content">
                    <div className="text">
                        Copyright © 2024 Shahiana | Site Designed by The SG | All Right Reserved
                    </div>
                    <div className="text_u">
                        <u onClick={() => navigate(`/termsandconditions`)}>Terms of Service</u> &nbsp;
                        <u onClick={() => navigate(`/privacypolicy`)}>Privacy Policy</u>
                    </div>
                </div>
            </div>
        </div>
    </footer >
};

export default Footer;
