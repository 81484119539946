import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import Category from "./components/Category/Category";
import SingleProduct from "./components/SingleProduct/SingleProduct";
import AppContext from "./utils/context";
import ScrollToTop from "./components/ScrollToTop";
import TermsAndConditions from "./components/Footer/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./components/Footer/PrivacyPolicy/PrivacyPolicy";
import ContactUs from "./components/ContactUs/ContactUs";
import ReturnRules from "./components/Footer/ReturnRules/ReturnRules";
import RARP from "./components/Footer/RARP/RARP";
import ShippingPolicy from "./components/Footer/ShippingPolicy/ShippingPolicy";
import AboutUs from "./components/AboutUs/AboutUs";


function App() {

    return (
        <BrowserRouter>
            <ScrollToTop />
            <AppContext>
                <Header />
                <Routes>
                    <Route exact path="/" element={<Home />}  component={AboutUs}/>
                    <Route path="/category/:id" element={<Category />} />
                    <Route path="/product/:id" element={<SingleProduct />} />
                    <Route path="/contactus" element={<ContactUs />} />
                    <Route path="/termsandconditions" element={<TermsAndConditions />} />
                    <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                    <Route path="/returnrules" element={<ReturnRules />} />
                    <Route path="/rarp" element={<RARP />} />
                    <Route path="/shippingpolicy" element={<ShippingPolicy />} />
                </Routes>
                <Footer />
            </AppContext>
        </BrowserRouter>);
}
export default App;