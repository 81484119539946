import OtherLinks from "../OtherLinks/OtherLinks"

export default function ReturnRules() {
    return (
        <>
            <OtherLinks sec_heading='Return Rules'>
                <div className="text">
                    <p>•	Returns accepted for 14 days</p>
                    <p>•	Free return shipping</p>
                    <p>•	No restocking fee</p>
                    <p>•	No final sale items</p>
                </div>
            </OtherLinks>
        </>
    )
}
