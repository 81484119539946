import "./ContactUs.scss";
import { FaFacebookF, FaInstagram, FaMobileAlt, FaEnvelope } from 'react-icons/fa';

function ContactUs() {
  return (
    <div className="contact-us">
      <div className="sec-heading">Contact Us</div>

      <div className="outer-text">
        Have you got any questions? Simply give us a call and we will be more than happy to help you out.

        <div className="box">
          <div className="c-items">
            <FaMobileAlt />
            <div className="text">
              <b>Phone</b>: +91 8897271818
            </div>
          </div>
          <div className="c-items">
            <FaEnvelope />
            <div className="text">
              <b>Email</b>: contact@shahiana.in
            </div>
          </div>
          <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=100092802851594&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
              <div className="icon">
                <FaFacebookF fill="#1877F2" size={22} />
              </div>
            </a>
            <a href="https://www.instagram.com/shahiana_in" target="_blank" rel="noopener noreferrer">

              <div className="icon">
                <FaInstagram fill="deeppink" size={22} />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs