import './AboutUs.scss';
import AboutShahiana from '../../assets/about_shahiana.jpeg';
import OurStory from '../../assets/our_story.jpeg';
import Offer from '../../assets/offer.jpeg';
function AboutUs() {
    return (
        <div id='aboutus' className="about-us">
            <div className="sec-heading">About Us</div>
            <div className='layout'>
                <div className="about-us-type" >
                    <img src={AboutShahiana} alt="category" />
                    <div className="info">
                        <h3>About Shahiana</h3>
                        Welcome to Shahiana, where the enchanting glow of Turkish lamps meets the vibrant spirit of India. Established with a passion for artistry and a commitment to bringing timeless elegance to homes across the country, we are your premier destination for authentic Turkish Lamps.
                    </div>
                </div>
                <div className="about-us-type" >
                    <img src={OurStory} alt="category" />
                    <div className="info">
                        <h3>Our Story</h3>
                        Founded 2021, Shahiana was born out of a love for the timeless artistry found in Turkish lamps. Our founders, driven by a love for cultural diversity and the desire to illuminate homes with exquisite craftsmanship, we embarked on a journey to curate a collection that marries the rich heritage of Turkish design with the warmth of Indian hospitality. Each lamp in our collection is a piece of art, handcrafted by skilled artisans who pour their expertise and passion into every detail.
                    </div>
                </div>
                <div className="about-us-type" >
                    <img src={Offer} alt="category" />
                    <div className="info">
                        <h3>What We Offer</h3>
                        At Shahiana, we offer a curated selection of Turkish lamps, each a masterpiece meticulously crafted by skilled artisans. From mosaic wonders to intricate metalwork, our lamps are designed to transform your living spaces into havens of warmth and sophistication. While inspired by Turkish design, our lamps are curated to seamlessly blend with the vibrant aesthetics of Indian homes.
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AboutUs