import "./Banner.scss";

const Banner = ({ handleClick }) => {

    const changeComponent = () => {
        handleClick();
    }

    return <div className="hero-banner">
        <div className="content">
            <div className="text-content">
                <h1>Anatolian Glow - Turkish Elegance</h1>
                <p>"Illuminate your space with the mesmerizing allure of Turkish Lamps. Crafted with artistry and cultural inspiration, our Turkish products infuse warmth and elegance into any room. Experience the enchantment of Turkish craftmanship, lighting up your world with royalty"</p>
                <div className="ctas">
                    {/* <div className="banner-cta">Read More</div> */}
                    <div className="banner-cta v2" onClick={changeComponent}>Shop Now</div>
                </div>
            </div>
            {/* <img className="banner-img" src={BannerImg} alt="" /> */}
        </div>
    </div>;
};

export default Banner;
