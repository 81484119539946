import './WhyChooseUs.scss';

function WhyChooseUs() {
    return (
        <div className="whychooseus">
            <div className="sec-heading">Why Choose Us?</div>

            <div className="layout">
                <div className="info">
                    <h3>Authenticity</h3>
                    We source directly from Turkish artisans to ensure the authenticity of every piece.
                </div>
                <div className="info">
                    <h3>Craftsmanship</h3>
                    Immerse yourself in the beauty of meticulous craftmanship, where every curve, every color, and every detail is a testament to the artisan’s skill.
                </div>
                <div className="info">
                    <h3>Versatility</h3>
                    Our collection caters to diverse tastes, from modern aesthetics to those seeking a touch of traditional charm.
                </div>
                <div className="info">
                    <h3>Variety</h3>
                    Explore a diverse range of designs, from intricate mosaic patterns to elegant metalwork, each telling a unique story.
                </div>
                <div className="info">
                    <h3>Customer Satisfaction</h3>
                    Your satisfaction is our priority. We're here to guide you through selecting the perfect piece for your space.
                </div>
            </div>


        </div>
    )
}

export default WhyChooseUs