import './OtherLinks.scss';

function OtherLinks({ sec_heading, children }) {
    return (
        <div className='other-links'>
            <div className="sec-heading">{sec_heading}</div>
            {children}
        </div>
    )
}

export default OtherLinks