import "./SingleProduct.scss";
import ReactImageMagnify from 'react-image-magnify';
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { useEffect, useRef, useState } from "react";

const SingleProduct = () => {
    const { id } = useParams();
    const { data } = useFetch(`/api/products?populate=*&[filters][id]=${id}`);
    const [image, setImage] = useState('');

    useEffect(() => {
        if (data) {
            setImage(data.data[0].attributes.img.data?.[0]?.attributes?.url);
        }
    }, [data]);

    const clickHandler = (image, i) => {
        setImage(image);
        refs.current[i]?.classList.add('active');
        for (let j = 0; j < product.img.data?.length; j++) {
            if (i !== j) {
                refs.current[j]?.classList.remove('active');
            }
        }
    }

    const refs = useRef([]);
    refs.current = [];
    const addRefs = (el) => {
        if (el && !refs.current.includes(el)) {
            refs.current.push(el);
        }
    }

    if (!data) return null;

    const product = data.data[0].attributes;
    const disc_percentage = ((product.price - product.discounted_price) / product.price) * 100;

    return <div className="single-prod-main-content">
        <div className="layout">
            <div className="single-product-page">
                <div className="left">
                    <div className="left-1">
                        {window.innerWidth > 600 ? <ReactImageMagnify {...{
                            smallImage: {
                                alt: 'No Data',
                                width: 600,
                                height: 500,
                                src: process.env.REACT_APP_DEV_URL + image
                            },
                            largeImage: {
                                src: process.env.REACT_APP_DEV_URL + image,
                                width: 1600,
                                height: 2000
                            }
                        }} /> : <img src={process.env.REACT_APP_DEV_URL + image} alt="no data found" />}
                    </div>
                    <div className="left-2">
                        {product.img.data?.map((img, i) => (
                            <div
                                key={i}
                                className={i === 0 ? 'img-wrap active' : 'img-wrap'}
                                onClick={() => clickHandler(img.attributes.url, i)}
                                ref={addRefs}
                            >
                                <img src={process.env.REACT_APP_DEV_URL + img.attributes.url} alt="no data found" />
                            </div>
                        ))}
                    </div>
                </div>

                <div className="right">
                    <span className="name">{product.title}</span>
                    <div className="price">
                        &#8377;{product.discounted_price}
                        <span className="off">({Math.round(disc_percentage)}% off)</span> &nbsp;
                        <span className="tax">inclusive of all taxes</span>
                    </div>
                    <span className="mrp">M.R.P : <s>&#8377;{product.price}</s></span>

                    <div className="info-item">
                        <span className="text-bold">SKU : {' '}
                            <span>{product.sku}</span>
                        </span>
                    </div>
                    <span className="desc_head">Description</span>
                    <span className="desc">{product.description}</span>

                    <span className="divider" />
                    <div className="info-item">
                        <span className="text-bold">Category : {' '}
                            <span>{product.categories.data[0].attributes.title}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default SingleProduct;

