import OtherLinks from "../OtherLinks/OtherLinks"

function ShippingPolicy() {
    return (
        <>
            <OtherLinks sec_heading='Shipping Policy'>
                <div className="text">
                    <b>Effective Date: 20th June 2023</b>

                    <div className="it">
                        Thank you for shopping at Shahiana! This Shipping Policy outlines important information regarding the shipping and delivery of products purchased from our website. By placing an order with us, you agree to comply with the terms of this Shipping Policy.
                    </div>

                    <b>1. Order Processing:</b>
                    <div className="it">
                        a. All orders are subject to verification and approval before shipment. We strive to process orders promptly, typically within 1-3 business days, excluding weekends and holidays. <br />
                        b. Once your order is processed and ready for shipment, you will receive an email confirmation with tracking information.
                    </div>

                    <b>2. Shipping Methods and Delivery Time:</b>
                    <div className="it">
                        a. We offer various shipping methods, which may include standard shipping, expedited shipping, or international shipping. The available options will be presented to you during the checkout process. <br />
                        b. The delivery time will vary depending on the shipping method selected, your location, and other factors beyond our control. <br />
                        c. Estimated delivery times are approximately 5-7 business days. We are not responsible for any delays caused by shipping carriers or unforeseen circumstances.
                    </div>

                    <b>3. Shipping Fees:</b>
                    <div className="it">
                        a. Shipping fees are calculated based on the total weight, dimensions, and destination of your order. The shipping cost will be displayed during the checkout process. <br />
                        b. Any additional customs duties, taxes, or import fees imposed by your country's customs authorities are your responsibility. Please check with your local customs office for more information on potential fees. <br />
                    </div>

                    <b>4. Tracking Your Order:</b>
                    <div className="it">
                        a. Once your order is shipped, you will receive a tracking number via email. You can use this tracking number to monitor the progress of your shipment. <br />
                        b. Please note that it may take up to 24-48 hours for the tracking information to be updated by the shipping carrier.
                    </div>

                    <b>5. Shipping Restrictions:</b>
                    <div className="it">
                        a. We currently ship within India. If your location is not eligible for shipping, you will be notified during the checkout process. <br />
                        b. Certain products may be subject to additional shipping restrictions or regulations based on their nature, size, or destination. We comply with all applicable laws and regulations regarding the shipping of such products.
                    </div>

                    <b>6. Order Changes and Cancellations:</b>
                    <div className="it">
                        a. Once an order is submitted, changes or cancellations may not be possible. Please review your order carefully before completing the purchase. <br />
                        b. If you need assistance or have any questions regarding your order, please contact our customer support team as soon as possible.
                    </div>

                    <b>7. Lost or Damaged Shipments:</b>
                    <div className="it">
                        a. While we take precautions to ensure that your order arrives safely, we are not responsible for lost or damaged shipments during transit. However, we will work with you and the shipping carrier to resolve any issues to the best of our ability.
                    </div>

                    <b>8. Contact Us:</b>
                    <div className="it">
                        If you have any questions, concerns, or requests regarding our Shipping Policy or your order, please contact our customer support team at shahiana.decors@gmail.com
                    </div>

                    <p>
                        Please review this Shipping Policy carefully before placing an order. By placing an order with Shahiana, you acknowledge that you have read, understood, and agreed to the terms and conditions outlined in this Shipping Policy.
                    </p>

                </div>
            </OtherLinks>
        </>
    )
}

export default ShippingPolicy