import "./Product.scss";
import { useNavigate } from 'react-router-dom';

const Product = ({ id, data }) => {
    const navigate = useNavigate();

    const disc_percentage = ((data.price - data.discounted_price) / data.price) * 100;

    return <div className="product-card" onClick={() => navigate(`/product/` + id)}>
        <div className="thumbnail">
            <img src={process.env.REACT_APP_DEV_URL + data.img.data?.[0].attributes.url} alt="no data found" />
        </div>

        <div className="prod-details">
            <span className="name">{data.title}</span>
            <div className="price">
                &#8377;{data.discounted_price}&nbsp;
                <span className="mrp"><s>&#8377;{data.price}</s> <span className="off">({Math.round(disc_percentage)}% off)</span></span> 

            </div>
        </div>
    </div>;
};

export default Product;
