import { useNavigate } from "react-router-dom";
import { TbSearch } from "react-icons/tb";
import Search from "./Search/Search";
import "./Header.scss";
import { useEffect, useState } from "react";
import logo from '../../assets/hdlogo.png';
import { Link, animateScroll as scroll } from 'react-scroll';
import { GiHamburgerMenu } from "react-icons/gi";
import { CgClose } from "react-icons/cg";


const Header = ({ handleClick }) => {
    const [scrolled, setScrolled] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [toggleDD, setToggleDD] = useState(false);

    const navigate = useNavigate();

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 200) {
            setScrolled(true);
        }
        else setScrolled(false);
    };

    const scrollToTop = () => {
        scroll.scrollToTop({
            duration: 800,
            smooth: 'easeInOutQuart',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    }, []);

    return (
        <>
            <nav className={`main-header ${scrolled ? 'sticky-header' : ''}`}>
                <div className="header-content">
                    <div className="toggle_btn">
                        <GiHamburgerMenu size={20} onClick={() => setToggleDD(!toggleDD)} />
                    </div>
                    <ul className="left">
                        <li onClick={() => {
                            navigate('/')
                            scrollToTop()
                        }}>
                            Home
                        </li>
                        <li>
                            <Link to="aboutus" smooth={true} duration={800}>
                                About Us
                            </Link>
                        </li>
                        <li>
                            <Link to="categories" smooth={true} duration={800}>
                                Categories
                            </Link>
                        </li>
                        <li onClick={() => navigate('/contactus')}>Contact Us</li>
                    </ul>
                    <div onClick={() => navigate('/')} className="center">
                        <img src={logo} alt="SHAHIANA.in" />
                    </div>
                    <div className="right">
                        <TbSearch onClick={() => setShowSearch(true)} />
                    </div>
                </div>

                <div className={`dropdown-menu ${toggleDD ? 'open' : ''}`}>
                    <li onClick={() => {
                        navigate('/')
                        scrollToTop()
                    }}>
                        Home
                    </li>
                    <li>
                        <Link to="aboutus" smooth={true} duration={800}>
                            About Us
                        </Link>
                    </li>
                    <li>
                        <Link to="categories" smooth={true} duration={800}>
                            Categories
                        </Link>
                    </li>
                    <li onClick={() => navigate('/contactus')}>Contact Us</li>
                </div>
            </nav>
            {showSearch && <Search setShowSearch={setShowSearch} />}
        </>
    )
};

export default Header;
