import OtherLinks from "../OtherLinks/OtherLinks"

function RARP() {
    return (
        <>
            <OtherLinks sec_heading='Return & Refund Policy'>
                <div className="text">
                    <p>
                        At Shahiana Decor we strive to provide you with a seamless shopping experience on our website. We place great importance on delivering our products with utmost care and maintaining the highest quality standards. As part of our policy, we do not accept returns for products that are delivered in perfect, undamaged condition. However, we do understand that certain situations may require a return, and we have outlined the conditions under which a product is eligible for return:
                    </p>
                    <div className="it">
                        <p>
                            1.	Wrong Product Dispatched or Item Mismatch: If you receive a product that does not match the item you selected during order confirmation, we apologise for the error. In such cases, please follow these steps: a. Contact our customer support team within 48 hours of receiving the product, providing the order details and explaining the issue. b. Our customer support team will guide you through the return process, which may involve returning the incorrect item and providing a replacement or refund.
                        </p>
                        <p>
                            2.	Quality or Manufacturing Defect: We take pride in the quality of our home decor products, but if you identify a quality or manufacturing defect in the received product, please follow these steps: a. Contact our customer support team within 48 hours of receiving the product, providing the order details and describing the defect. b. Our customer support team will assess the situation and guide you through the return process, which may involve returning the defective item and providing a replacement or refund.
                        </p>
                        <p>
                            3.	Product Received in a Damaged Condition: We understand that receiving a damaged product can be disappointing. If you receive a product in a damaged condition, please follow these steps: a. Contact our customer support team within 48 hours of receiving the product, providing the order details and sharing clear photographs or videos that clearly show the damage. b. Our customer support team will assess the situation and guide you through the return process, which may involve returning the damaged item and providing a replacement or refund.
                        </p>
                    </div>
                    <p>
                        Please note that our refund policy only applies to products that meet the conditions mentioned above. We strive to ensure your satisfaction with our products, and we will work diligently to resolve any issues you may encounter.
                    </p>
                </div>
            </OtherLinks>
        </>
    )
}

export default RARP