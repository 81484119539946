import "./Home.scss";
import { useEffect, useContext, useRef } from "react";
import Banner from './Banner/Banner';
import Category from "./Category/Category";
import { fetchDataFromApi } from "../../utils/api";
import AboutUs from "../AboutUs/AboutUs";
import WhyChooseUs from "../WhyChooseUs/WhyChooseUs";

import { Context } from "../../utils/context";

const Home = ({ reference }) => {
    const ref = useRef(null);
    const { categories, setCategories, setProducts } = useContext(Context)

    useEffect(() => {
        getCategories();
        getProducts();
    }, []);

    const getProducts = () => {
        fetchDataFromApi("/api/products?populate=*").then((res) => {
            setProducts(res);
        });
    };

    const getCategories = () => {
        fetchDataFromApi("/api/categories?populate=*").then((res) => {
            setCategories(res);
        });
    };

    const handleClick = () => {
        ref.current?.scrollIntoView({
            behavior: 'smooth'
        });
    };


    return (
        <div>
            <Banner handleClick={handleClick} />
            <div className="main-content">
                <div className="layout">
                    <div ref={reference}>
                        <AboutUs />
                    </div>
                    <div ref={ref} id="categories">
                        <Category categories={categories} />
                    </div>
                    <WhyChooseUs />
                </div>
            </div>
        </div>
    );
};

export default Home;
